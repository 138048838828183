import request from '@/utils/request'


// 查询房间背景图列表
export function listRoom(query) {
  return request({
    url: '/biz/roomImage/list',
    method: 'get',
    params: query
  })
}

// 查询房间背景图分页
export function pageRoom(query) {
  return request({
    url: '/biz/roomImage/page',
    method: 'get',
    params: query
  })
}

// 查询房间背景图详细
export function getRoom(data) {
  return request({
    url: '/biz/roomImage/detail',
    method: 'get',
    params: data
  })
}

// 新增房间背景图
export function addRoom(data) {
  return request({
    url: '/biz/roomImage/add',
    method: 'post',
    data: data
  })
}

// 修改房间背景图
export function updateRoom(data) {
  return request({
    url: '/biz/roomImage/edit',
    method: 'post',
    data: data
  })
}

// 删除房间背景图
export function delRoom(data) {
  return request({
    url: '/biz/roomImage/delete',
    method: 'post',
    data: data
  })
}
